// window.$ = window.jQuery = require('jquery');
// import '../bootstrap-table/bootstrap-table'
import moment from 'moment'
window.moment = moment
import select2 from 'select2'
window.select2 = select2
import 'select2/dist/css/select2.css'
import 'select2/dist/js/i18n/zh-TW'

import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap-table/dist/bootstrap-table.css'
import 'bootstrap-table/dist/bootstrap-table'
import 'bootstrap-table/dist/locale/bootstrap-table-zh-TW'
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control'
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.css'
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie'

import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/plugin'
import 'jquery-ui/ui/widgets/mouse'
import 'jquery-ui/ui/widgets/sortable'

import 'tablednd/js/jquery.tablednd'
import '../dragtable'
import 'bootstrap-table/dist/extensions/reorder-columns/bootstrap-table-reorder-columns'
import 'bootstrap-table/dist/extensions/reorder-rows/bootstrap-table-reorder-rows'
// import 'bootstrap-table/dist/extensions/sticky-header/bootstrap-table-sticky-header'
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns.css'
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns'

import 'bootstrap-table/dist/extensions/export/bootstrap-table-export'
// import 'tableexport.jquery.plugin/tableExport.min.js'

import 'bootstrap-table/dist/extensions/page-jump-to/bootstrap-table-page-jump-to.css'
import 'bootstrap-table/dist/extensions/page-jump-to/bootstrap-table-page-jump-to'
import 'bootstrap-table/dist/extensions/print/bootstrap-table-print'

import '../tableExport.min.js'
import '../bootstrap-table-custom.js'

import '@claviska/jquery-minicolors'
import '../jquery-countdown'

import '../jquery.mark.js'
import '../highlight.js'

import '../print_area.js'
import 'bootstrap-fileinput/js/fileinput';
import 'bootstrap-fileinput/css/fileinput.css';
import 'bootstrap-fileinput/js/locales/zh-TW';

import '../editable_table.js'
