window.initEditableTable = function($table, edit_link, model) {
  $table.on('click-cell.bs.table', function(e, field, value, row, $element) {
    if (!$element.hasClass('editable')) {
      return;
    }
    var edit_url = window.location.origin + '/' + edit_link + '/' + row.id + '/edit';
    var update_url = window.location.origin + '/' + edit_link + '/' + row.id;
    var editableAttribute = $table.find('th[data-field="' + field + '"]').attr('data-editable-attribute');
    if (!editableAttribute) {
      editableAttribute = field;
    }
    console.log(model + '/' + editableAttribute);
    $.ajax({
      url: edit_url,
      method: 'GET',
      dataType: 'script',
      success: function(data) {
        $('#edit-row-modal .modal-body .form-group').each(function() {
          if (!$(this).hasClass(model + '_' + editableAttribute)) {
            $(this).remove();
          }
        });
        $('#edit-row-modal .form-inline').each(function() {
          if (!$(this).hasClass(model + '_' + editableAttribute)) {
            $(this).remove();
          }
        });
        $('#edit-row-modal').modal('show');

        $('form.simple_form').on('submit', function(e) {
          e.preventDefault();

          var formData = $(this).serialize();

          $.ajax({
            url: update_url,
            method: 'PATCH',
            data: formData,
            dataType: 'json',
            success: function(updatedData) {
              $('#edit-row-modal').modal('hide');
              console.log(updatedData)

              $table.bootstrapTable('updateByUniqueId', {
                id: updatedData.id,
                row: updatedData
              });
            }
          });
        });
      }
    });
  });
}