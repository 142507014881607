window.sumFormatter = function(data) {
    var field = this.field;
    regex = /(<([^>]+)>)/ig;

    var total_sum = data.reduce(function(sum, row) {
        price = row[field].replace('<div style=" display: block-inline;" class="text-right ">', '')
            .replace('<div style="color: red; display: block-inline;" class="text-right ">', '')
            .replace('$ ', '')
            .replace(/,/g, '')
            .replace('</div>', '')
            .replace(regex, '').replace(/\s+/g, '');

        // console.log( price );
        return (sum) + (parseFloat(price) || 0);
    }, 0);
    return '<div style="display:inline;" class="text-right">$ ' + total_sum.toLocaleString() + '</div>';
}

window.hiddenSumFormatter = function(data) {
  var field = this.field;
  var total = 0;
  data.forEach(function (row) {
    var htmlContent = row[this.field];

    var hiddenValueMatch = htmlContent.match(/<div style="display: none;">([\d.-]+)<\/div>/);

    if (hiddenValueMatch) {
      var hiddenValue = parseFloat(hiddenValueMatch[1].replace(/,/g, ''));
      if (!isNaN(hiddenValue)) {
        total += hiddenValue;
      }
    }
  }, this);

  return '<div style="display:inline;" class="text-right">$ ' + total.toLocaleString() + '</div>';
}

window.totalFormatter = function(data) {
    var field = this.field;
    regex = /(<([^>]+)>)/ig;

    var total_sum = data.reduce(function(sum, row) {
        price = row[field].replace('<div style=" display: block-inline;" class="text-right ">', '')
            .replace('<div style="color: red; display: block-inline;" class="text-right ">', '')
            .replace('$ ', '')
            .replace(/,/g, '')
            .replace('</div>', '')
            .replace(regex, '').replace(/\s+/g, '');

        // console.log( price );
        return (sum) + (parseFloat(price) || 0);
    }, 0);
    return '<div style="display:inline;" class="text-right">' + total_sum + '</div>';
}

window.trueFormatter = function(value) {
    if ( value ){
        var str = ((value === "true") || (value === "是") || (value === true)) ? "是" : "否";
        var color = ((value === "true") || (value === "是") || (value === true)) ? "green" : "red";
        return '<div style="color: ' + color + '">' + str + '</div>';
    } else {
        return null;
    }
}

window.rocDateFormatter = function(value, row) {
    if (value === null){
      return null;
    }

    year = moment(value).subtract(1911, 'years').year();
    return year + moment(value).format('年M月D日');
}

window.responseHandler = function(res) {
    $.each(res.rows, function(i, row) {
        row.state = $.inArray(row.id, selections) !== -1
    })
    return res
}

window.priceSorter = function(a, b) {
    a = a.substring(
        a.indexOf(">") + 1,
        a.lastIndexOf("<")
    ).replace(/,/g,'').replace('$ ', '')
    b = b.substring(
        b.indexOf(">") + 1,
        b.lastIndexOf("<")
    ).replace(/,/g,'').replace('$ ', '')

    if (parseFloat(a) > parseFloat(b)) return 1;
    if (parseFloat(a) < (b)) return -1;
    return 0;
}

$(document).ready(function(){
  $('.table').on('reorder-row.bs.table', function (e, rows) {
      updated_position = {}
      $.each(rows, function(i, row){
        updated_position[row.reorder_sgid] = i;
      })

      var tableElement = $(e.target).closest('.table');
      update_position(updated_position);
  });
});

function update_position(updated_position){
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: "PATCH",
        url: '/reorder',
        data: {
          updated_position: updated_position
        },
        success: function(data) {
          console.log(data);
          $('.table').bootstrapTable('refresh');
        },
        error: function(jqXHR) {
          console.log(jqXHR);
        }
    })
}

